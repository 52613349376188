import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'
import PageLayout from 'components/Layout/PageLayout'

function NotFound({ location }) {
	return (
		<Layout location={location} title="Not Found">
			<PageLayout>
				<h1>Not found</h1>
			</PageLayout>
		</Layout>
	)
}
NotFound.propTypes = {
	location: PropTypes.object,
}

export default NotFound
